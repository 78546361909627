body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#main-router {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
}

.popup-content {
  left: 0px;
  width: 98vw!important;
}

.app-preview {
  display: none;
}

.disclaimer {
  color: white;
  font-weight: bold;
  font-style: italic;
  margin-top: 10px;
}

#add-player-page {
    text-align: center;
    background: -webkit-linear-gradient(bottom,#00486b 0,#00529e);
    background: linear-gradient(0deg,#00486b 0,#00529e);
    height: 100vh;
    width: 100%;
    margin-left: auto;
   margin-right: auto;
 }

.edit-modal .popup-content {
  width: 50%!important;
}

@media(min-width: 1000px) {
  .app-body {
    width: 30%;
    position: absolute;
    right: 30px;
    top: 50px;
  }
  .swipe-trigger {
    display: none;
  }
  .swipe-list {
    width: 100%;
  }
  #add-player-page {
    text-align: center;
    background: -webkit-linear-gradient(bottom,#00486b 0,#00529e);
    background: linear-gradient(0deg,#00486b 0,#00529e);
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }
  .app-preview {
    margin-top: 10px;
    display: unset;
    width: 60%;
    min-height: 90vh;
    margin-left: 70px;
  }
}

@media(min-width: 1300px) {
  .app-preview {
    margin-top: 10px;
    display: unset;
    width: 60%;
    min-height: 90vh;
    margin-left: 70px;
  }
}

@media(min-width: 1500px) {
  .app-body {
    width: 30%;
    position: absolute;
    right: 70px;
    top: 50px;
  }
  .app-preview {
    margin-top: 10px;
    display: unset;
    width: 60%;
    min-height: 90vh;
    margin-left: 70px;
  }
}
